<template>
  <div class="grid align-center">
    <div class="col-4 col-offset-1">
      <LabelStyled :isActive="webhookstatus" class="label-styled">
        {{
          $t("partner_data.partnerinfo_webhook_status") +
          ": " +
          (webhookstatus ? $t("general.active") : $t("general.inactive"))
        }}
      </LabelStyled>
    </div>
    <div class="col-3 col-offset-3 flex justify-content-end">
      <Button
        severity="info"
        :label="$t('partner_data.partnerinfo_webhook')"
        :disabled="disablewebhookstatus"
        @click="enableWebhook"
      />
    </div>
  </div>
  <div style="max-width: min(100vw, 1000px)" class="grid">
    <div class="grid col-12">
      <TextInput
        :label="$t('partner_data.partnerinfo_amount')"
        class="col-12 md:col-12"
        v-model="VIVAWALLET.VIVAWALLET_AMOUNT.key"
      ></TextInput>
      <TextInput
        :label="$t('partner_data.partnerinfo_terminalmerchant')"
        class="col-12 md:col-12"
        v-model="VIVAWALLET.VIVAWALLET_TERMINALMERCHANT.key"
      ></TextInput>
      <TextInput
        :label="$t('partner_data.partnerinfo_apikey')"
        class="col-12 md:col-12"
        v-model="VIVAWALLET.VIVAWALLET_APIKEY.key"
      ></TextInput>
      <TextInput
        :label="$t('partner_data.partnerinfo_apisecret')"
        class="col-12 md:col-12"
        v-model="VIVAWALLET.VIVAWALLET_APISECRET.key"
      ></TextInput>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect, onMounted, computed } from "vue";
import LabelStyled from "@/components/common/form/LabelStyled.vue";

import { useI18n } from "vue-i18n";
const { t } = useI18n();

import { useToast } from "primevue/usetoast";
const toast = useToast();

import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
const { partner, partnerInfo } = storeToRefs(userStore);

const VIVAWALLET = ref({
  VIVAWALLET_AMOUNT: { id: 0, key: null },
  VIVAWALLET_TERMINALMERCHANT: { id: 1, key: null },
  VIVAWALLET_APIKEY: { id: 2, key: null },
  VIVAWALLET_APISECRET: { id: 3, key: null },
});

const props = defineProps({
  disablewebhook: Boolean,
});

const webhookstatus = ref(false);
const checkwebhookativation = ref(false);
const disablewebhookstatus = computed(() => {
  if (!webhookstatus.value) {
    return false;
  } else if (!props.disablewebhook && !checkwebhookativation.value) {
    return false;
  } else {
    return true;
  }
});

const emit = defineEmits(["update:vivawallet"]);

watchEffect(() => {
  partnerInfo.value.forEach((info) => {
    switch (info.chave) {
      case "VIVAWALLET_AMOUNT":
        VIVAWALLET.value.VIVAWALLET_AMOUNT.key = info.valor;
        break;
      case "VIVAWALLET_TERMINALMERCHANT":
        VIVAWALLET.value.VIVAWALLET_TERMINALMERCHANT.key = info.valor;
        break;
      case "VIVAWALLET_APIKEY":
        VIVAWALLET.value.VIVAWALLET_APIKEY.key = info.valor;
        break;
      case "VIVAWALLET_APISECRET":
        VIVAWALLET.value.VIVAWALLET_APISECRET.key = info.valor;
        break;
    }
  });
});

// watch(
//   [
//     () => VIVAWALLET.value.VIVAWALLET_AMOUNT.key,
//     () => VIVAWALLET.value.VIVAWALLET_TERMINALMERCHANT.key,
//     () => VIVAWALLET.value.VIVAWALLET_APIKEY.key,
//     () => VIVAWALLET.value.VIVAWALLET_APISECRET.key,
//   ],
//   (
//     [newVIVAWALLET_AMOUNT, newTERMINALMERCHANT, newAPIKEY, newAPISECRET],
//     [oldVIVAWALLET_AMOUNT, oldTERMINALMERCHANT, oldAPIKEY, oldAPISECRET]
//   ) => {
//     if (
//       newTERMINALMERCHANT !== oldTERMINALMERCHANT ||
//       newAPIKEY !== oldAPIKEY ||
//       newAPISECRET !== oldAPISECRET
//     ) {
//       enablewebhook.value = false;
//     }
//     emit("update:vivawallet", VIVAWALLET.value);
//   },
//   { deep: true }
// );
watch(
  () => VIVAWALLET.value,
  (newVIVAWALLET) => {
    emit("update:vivawallet", VIVAWALLET.value);
  },
  { deep: true }
);

const enableWebhook = async () => {
  const vivaPayload = {
    api_key: VIVAWALLET.value.VIVAWALLET_APIKEY.key,
    api_secret: VIVAWALLET.value.VIVAWALLET_APISECRET.key,
    merchant_id: VIVAWALLET.value.VIVAWALLET_TERMINALMERCHANT.key,
    revendedor_id: partner.value.codigo.toString(),
  };

  const resp = await userStore.enableWebhook(vivaPayload);

  if (resp.status === "ok") {
    toast.add({
      severity: "success",
      summary: t("partner_data.partnerinfo_activate_webhook"),
      group: "c",
      detail: "",
      life: 6000,
    });

    checkwebhookativation.value = true;
  }
};

const getVivaWalletWebhookStatus = async () => {
  await userStore.getVivaWalletWebhookStatus().then((r) => {
    webhookstatus.value = false;
    if (r && Object.keys(r).length !== 0) {
      const status = JSON.parse(r);
      webhookstatus.value = status.Response.Content.status === 1;
    }
  });
};

onMounted(() => {
  getVivaWalletWebhookStatus();
});
</script>

<style scoped>
.grid.align-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.label-styled {
  margin-right: 10px;
}
</style>
