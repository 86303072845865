const defaultStore = {
  cliente: 0,
};

const defaultLicense = {
  cliente: 0,
  nome: "",
  contribuinte: "",
  morada: "",
  cp1: "",
  cp2: "",
  telefone: "",
  localidade: "",
  software: "",
  licencas: 1,
  email: "",
  obs: "",
  softcod: 0,
  tipopedido: 1,
  codlicenca: 0,
  versao: "",
  pais: "PT",
  // browserlanguage: navigator.language.split("-")[1].toUpperCase(),
  email_cliente: "",
  nome_contacto_cliente: "",
  hardware: -1,
  campanha_id: -1,
  campanha_titulo: "",
  modulos: "",
  tipo_lic: 0,
  internet: 0,
  serial: "",
  freebee: 0,
  trial: 0,
  dias_trial: 0,
  type_online: 0,
  users_online: 0,
};

const countryList = [
  { name: "Portugal", code: "PT" },
  { name: "Spain", code: "ES" },
  { name: "Germany", code: "DE" },
  { name: "Brazil", code: "BR" },
  { name: "United States", code: "US" },
];

const fidelizationList = [
  { name: "Nenhuma1", code: -1 },
  { name: "Freebee Base", code: 1 },
  { name: "Freebee Plus", code: 2 },
  { name: "Freebee Plus+", code: 3 },
];

const SOFTWARE = {
  ZSFACT: 1,
  ZSGO: 13,
  ZSPOS: 2,
  ZSPOSMOBILE: 11,
  ZSREST: 4,
};

const defaultVIVAWALLET = [
  { id: 0, key: "VIVAWALLET_AMOUNT" },
  { id: 1, key: "VIVAWALLET_TERMINALMERCHANT" },
  { id: 2, key: "VIVAWALLET_APIKEY" },
  { id: 3, key: "VIVAWALLET_APISECRET" },
];

const defaultPartnerInfo = {
  revendedor: null,
  chave: null,
  valor: null,
};

export {
  defaultStore,
  defaultLicense,
  countryList,
  fidelizationList,
  SOFTWARE,
  defaultVIVAWALLET,
  defaultPartnerInfo,
};
