<template>
  <label :style="computedStyle">
    <slot></slot>
  </label>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  isActive: {
    type: Boolean,
    required: true,
  },
});

const computedStyle = computed(() => ({
  backgroundColor: props.isActive ? "#d4edda" : "#f8d7da",
  color: props.isActive ? "#155724" : "#721c24",
  padding: "5px",
  borderRadius: "5px",
}));
</script>

<style scoped>
label {
  display: inline-block;
}
</style>
